import { RouteRecordRaw } from "vue-router";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import routesName from "@/utils/constants/routesName";
import ProfileLayout from "@/layouts/ProfileLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: routesName.landing,
    component: () => import("@/views/LandingView.vue"),
  },
  {
    path: "/login",
    name: routesName.login,
    component: () => import("@/views/auth/LoginView.vue"),
    meta: { notAuthorized: true, layout: AuthLayout },
  },
  {
    path: "/registration",
    name: routesName.registration,
    component: () => import("@/views/auth/RegistrationView.vue"),
    meta: { notAuthorized: true, layout: AuthLayout },
  },
  {
    path: "/confirm/:token",
    name: routesName.confirmAccount,
    component: () => import("@/views/auth/ConfirmAccount.vue"),
    meta: { notAuthorized: true, layout: AuthLayout },
  },
  {
    path: "/reset-password",
    children: [
      {
        path: "",
        name: routesName.reset,
        component: () => import("@/views/auth/ResetPasswordView.vue"),
        meta: { notAuthorized: true, layout: AuthLayout },
      },
      {
        path: ":token",
        name: routesName.restore,
        component: () => import("@/views/auth/ResetPasswordView.vue"),
        meta: { notAuthorized: true, layout: AuthLayout },
      },
    ],
  },
  {
    path: "/learning-centers",
    name: routesName.centersList,
    component: () => import("@/views/LearningCentersView.vue"),
  },
  {
    path: "/learning-centers/:id",
    name: routesName.center,
    component: () => import("@/views/LearningCenterDetailsView.vue"),
  },
  {
    path: "/learning-centers/:id/module/:moduleId",
    name: routesName.module,
    component: () => import("@/views/ModuleDetailsView.vue"),
  },
  {
    path: "/my-courses",
    name: routesName.myCourses,
    component: () => import("@/views/MyCoursesView.vue"),
    meta: { protected: true },
  },
  {
    path: "/onboarding",
    name: routesName.profileOnboarding,
    component: () => import("@/views/ProfileOnboardingView.vue"),
    meta: { protected: true },
  },
  {
    path: "/my-certificates",
    name: routesName.myCertificates,
    component: () => import("@/views/MyCertificatesView.vue"),
    meta: { protected: true },
  },
  {
    path: "/courses",
    name: routesName.coursesList,
    component: () => import("@/views/CourseListView.vue"),
  },
  {
    path: "/courses/:id",
    name: routesName.course,
    component: () => import("@/views/CourseDetailsView.vue"),
  },
  {
    path: "/courses/:id/follow-up",
    name: routesName.courseFollowUp,
    component: () => import("@/views/CourseFollowUpView.vue"),
  },
  {
    path: "/courses/:id/onboarding/:step",
    name: routesName.onboardingStep,
    meta: { layout: EmptyLayout, protected: true },
    component: () => import("@/views/OnboardingStartCourseView.vue"),
  },
  {
    path: "/faq",
    name: routesName.faq,
    component: () => import("@/views/FAQView.vue"),
  },
  {
    path: "/contact-us",
    name: routesName.contactUs,
    component: () => import("@/views/ContactUsView.vue"),
  },
  {
    path: "/partners",
    name: routesName.partners,
    component: () => import("@/views/PartnersView.vue"),
  },
  {
    path: "/profile/dashboard",
    name: routesName.dashboard,
    component: () => import("@/views/profile/DashboardView.vue"),
    meta: { protected: true, layout: ProfileLayout },
  },
  {
    path: "/profile/my-courses",
    name: routesName.myCourses,
    component: () => import("@/views/profile/MyCoursesView.vue"),
    meta: { protected: true, layout: ProfileLayout },
  },
  {
    path: "/profile/my-certificates",
    name: routesName.myCertificates,
    component: () => import("@/views/profile/MyCertificatesView.vue"),
    meta: { protected: true, layout: ProfileLayout },
  },
  {
    path: "/profile/my",
    name: routesName.myProfile,
    component: () => import("@/views/profile/MyProfileView.vue"),
    meta: { protected: true, layout: ProfileLayout },
  },
  {
    path: "/profile/settings",
    name: routesName.mySettings,
    component: () => import("@/views/profile/SettingsView.vue"),
    meta: { protected: true, layout: ProfileLayout },
  },
  {
    path: "/specialities",
    name: routesName.specialitiesList,
    component: () => import("@/views/SpecialitiesView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: routesName.notFound,
    meta: { protected: true },
    component: () => import("@/views/NotFoundView.vue"),
  },
];

export default routes;
